import styled from "styled-components";

export const NormalTypography = styled.p`
  font-size: 18px;
  line-height: 1.8;
`;

export const HeadingsTypography = styled.h2`
  font-weight: 700;
  font-size: 36px;
`;

export const SubHeadingsTypography = styled.h4`
  font-weight: 700;
  font-size: 22px;
  line-height: 1.8;
`;